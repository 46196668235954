<template lang="pug">
  //- div(ref="cytocarpet" style="background-color: rgb(28,32,39); min-height: 100vh")
  div(style="min-height: 100vh")
    .p.carpet-title(@click="centerNetwork")
      | The EMBO Membership

    .button.is-loading.is-fullwidth.is-dark-mode(v-if="isLoading")

    .is-clearfix.button-toolbar(v-if="!isLoading")
      .button.is-biggish.is-dark-mode.is-pulled-left.has-just-icon-mobile(@click="resetFiltersAndCenter" v-touch:longtap="hardReloadWebpage")
        span.icon
          fa-icon(icon="home")
        span.help-text reset
      .button.is-biggish.is-dark-mode.is-pulled-left.has-just-icon-mobile(@click="resetFilters")
        span.icon
          fa-icon(icon="th")
        span.help-text all
      .button.is-biggish.is-dark-mode.is-pulled-left.has-just-icon-mobile(@click="centerCurrentSelection")
        span.icon
          fa-icon(icon="compress-arrows-alt")
        span.help-text fit
      .button.is-biggish.is-dark-mode.is-pulled-left.has-just-icon-mobile(@click="centerNetwork")
        span.icon
          fa-icon(icon="arrows-alt")
        span.help-text out
      router-link(to="/about").button.is-biggish.is-dark-mode.is-pulled-left.has-just-icon-mobile
        span.icon
          fa-icon(icon="info")
        span.help-text about
      .button.is-biggish.is-dark-mode.is-pulled-left.has-just-icon-mobile(@click="showSubjectAreasFilter = !showSubjectAreasFilter")
        span.icon
          fa-icon(icon="ellipsis-h")
        span.help-text more


    .is-clearfix.subjectarea-toolbar(v-if="!isLoading && showSubjectAreasFilter")
      .button.is-biggish.is-dark-mode.is-pulled-left.has-just-icon-mobile(v-for="subjectArea in subjectAreas" @click="filterBySubjectArea(subjectArea)")
        span.icon(:class="[subjectArea.cssClass]")
          fa-icon(icon="square-full")
        span.help-text {{ subjectArea.shortName }}


    .field.has-addons.search-toolbar(v-if="!isLoading")
      .control.has-icons-left
        input.input(placeholder='search', v-model="searchQuery", @keyup="search(searchQuery)", ref="search")
        span.icon.is-left
          fa-icon(icon="search")
      .control(@click="resetFilters")
        .button.is-biggish.is-dark-mode
          span.icon
            fa-icon(icon="times")
    
    .result-count.is-hidden-mobile {{ resultCount }}

    .carpet-node-info(v-if="currentMember && showNodeInfo")
      CarpertNodeInfo(:current-member="currentMember" @clickKeyword="filterByKeyword" @clickClose="hideMember")
    .columns
      .column
        cytoscape.network(
          :config="config" ,
          :preConfig="preConfig",
          :afterCreated="afterCreated",
        )


</template>

<script>
import 'vue-cytoscape/dist/vue-cytoscape.css'
import { debounce } from 'lodash'
import anywherePanning from 'cytoscape-anywhere-panning'
import { saveAs } from 'file-saver'
import http from '@/lib/http'
import CarpertNodeInfo from './carpet-node-info'
import networkStyle from './network-style'

let ALL_EDGES = null
const CY_FIT_PADDING = 100


function _search (query) {
  const fullTextQueryRegex = new RegExp(query, 'i')
  const nameQueryRegex = new RegExp(`^${query}`, 'i')
  this.$cytoscape.instance.then((cy) => {
    removeHighlight(cy)
    this.currentMember = null
    cy.nodes().addClass('dimmed')
    const results = cy.nodes().filter((node) => {
      const itMatchesFullTextSearchFields = fullTextQueryRegex.exec([
        node.data().PS_EMBO_Year_Membership,
        node.data().SC_ML_OfficialKeywords,
        node.data().SC_EMBOwords__lct,
        node.data().SC_SubjectArea_1st__lct,
        node.data().SC_SubjectArea_2nd__lct,
        // node.data().psub_add_INST__Institution_Current_ContactOnly_IN_FullName_Print__lct,
        node.data().psub_add_inst_COU__Country_Current_ContactOnly_Country_Name,
      ].join(' '))
      const itMatchesFirstnameBeginsWith = nameQueryRegex.exec(node.data().PS_FirstNamePlusInitials)
      const itMatchesLastnameBeginsWith  = nameQueryRegex.exec(node.data().PS_LastName)

      return itMatchesFullTextSearchFields || itMatchesFirstnameBeginsWith || itMatchesLastnameBeginsWith
    }).forEach((node) => {
      cy.getElementById(node.data().id).removeClass('dimmed').addClass('not-dimmed')
    })
    if (results.length === 1) {
      this.currentMember = results[0].data()
    }
    this.resultCount = results.length
    // cy.resize()
    cy.fit(cy.$('node.not-dimmed'), CY_FIT_PADDING)
  })
}

function highlightNodeAndNeighbours (node, cy) {
  cy.remove('edge')
  cy.nodes().removeClass('selected').removeClass('not-dimmed')
  const nodeEdges = ALL_EDGES.filter((edge) => {
    return (edge.data.source === node.id || edge.data.target === node.id)
  }).map((edge) => {
    return Object.assign({ group: 'edges' }, edge)
  })
  cy.add(nodeEdges)
  cy.nodes().addClass('dimmed')
  cy.getElementById(node.id).removeClass('dimmed').addClass('selected')
  cy.getElementById(node.id).neighborhood().removeClass('dimmed').addClass('not-dimmed')
}

function removeHighlight (cy) {
  cy.remove('edge')
  cy.nodes().removeClass('dimmed').removeClass('selected').removeClass('not-dimmed')
}

export default {
  components: {
    CarpertNodeInfo,
  },
  data () {
    return {
      resultCount: 0,
      showNodeInfo: true,
      isLoading: true,
      allEdges: [],
      currentMember: null,
      currentSubjectArea: '',
      showSubjectAreasFilter: false,
      searchQuery: '',
      subjectAreas: [
        {
          name: 'Cell & Tissue Architecture',
          shortName: 'CellTissArchit',
          cssClass: 'sa-cell-tissue-architecture',
        },
        {
          name: 'Cell Cycle',
          shortName: 'CellCycle',
          cssClass: 'sa-cell-cycle',
        },
        {
          name: 'Cellular Metabolism',
          shortName: 'CellMetab',
          cssClass: 'sa-cellular-metabolism',
        },
        {
          name: 'Chromatin & Transcription',
          shortName: 'Chromatin',
          cssClass: 'sa-chromatin-transcription',
        },
        {
          name: 'Development',
          shortName: 'DevBio',
          cssClass: 'sa-development',
        },
        {
          name: 'Differentiation & Death',
          shortName: 'DiffDeath',
          cssClass: 'sa-differentiation-death',
        },
        {
          name: 'Evolution & Ecology',
          shortName: 'EvoEco',
          cssClass: 'sa-evolution-ecology',
        },
        {
          name: 'Genome Stability & Dynamics',
          shortName: 'GenomStabDyn',
          cssClass: 'sa-genome-stability-dynamics',
        },
        {
          name: 'Genomic & Computational Biology',
          shortName: 'GenomComputBio',
          cssClass: 'sa-genomic-computational-biology',
        },
        {
          name: 'Immunology',
          shortName: 'Immuno',
          cssClass: 'sa-immunology',
        },
        {
          name: 'Membranes & Transport',
          shortName: 'MembTransp',
          cssClass: 'sa-membranes-transport',
        },
        {
          name: 'Microbiology, Virology & Pathogens',
          shortName: 'MicroViroPatho',
          cssClass: 'sa-microbiology-virology-pathogens',
        },
        {
          name: 'Molecular Medicine',
          shortName: 'MolMed',
          cssClass: 'sa-molecular-medicine',
        },
        {
          name: 'Neuroscience',
          shortName: 'Neuro',
          cssClass: 'sa-neuroscience',
        },
        {
          name: 'Plant Biology',
          shortName: 'PlantBio',
          cssClass: 'sa-plant-biology',
        },
        {
          name: 'Proteins & Biochemistry',
          shortName: 'ProteinBiochem',
          cssClass: 'sa-proteins-biochemistry',
        },
        {
          name: 'RNA',
          shortName: 'RNA',
          cssClass:'sa-rna',
        },
        {
          name: 'Signal Transduction',
          shortName: 'SignalTrans',
          cssClass: 'sa-signal-transduction',
        },
        {
          name: 'Structural Biology & Biophysics',
          shortName: 'StructBiophys',
          cssClass: 'sa-structural-biology-biophysics',
        },
        {
          name: 'Systems Biology',
          shortName: 'SystBio',
          cssClass: 'sa-systems-biology',
        },
      ],
    }
  },
  computed: {
    config () {
      return {
        style: networkStyle,
        layout: {
          name: 'preset',
        },
        elements: this.getElements(),
      }
    },
  },
  methods: {
    centerNetwork () {
      this.$cytoscape.instance.then((cy) => {
        cy.resize()
        cy.fit()
      })
    },
    centerCurrentSelection () {
      this.$cytoscape.instance.then((cy) => {
        cy.resize()
        cy.fit(cy.$('node.not-dimmed'), CY_FIT_PADDING)
      })
    },
    preConfig (cytoscape) {
      // cytoscape: this is the cytoscape constructor
      cytoscape.use(anywherePanning)
    },
    afterCreated (cy) {
      // cy: this is the cytoscape instance
      cy.anywherePanning()
      cy.boxSelectionEnabled(false)
      cy.autoungrabify(true)
      cy.minZoom(0.15)
      cy.maxZoom(10)
    },
    getElements (params) {
      const url = '/networks.json'
      return http.get(url, { params })
        .then((result) => {
          const { elements } = result.data['top10_neighbours--MCL_asymweights_nocutoff_1']
          console.debug(elements)
          // this.elements = elements
          setTimeout(() => this.ungrabifyNodes(), 5000)
          setTimeout(() => this.ungrabifyNodes(), 10000)
          setTimeout(() => this.ungrabifyNodes(), 15000)
          setTimeout(() => this.ungrabifyNodes(), 25000)
          setTimeout(() => this.ungrabifyNodes(), 60000)
          // return elements
          ALL_EDGES = elements.edges
          this.resultCount = elements.nodes.length

          return {
            nodes: elements.nodes,
          }
        })
    },
    ungrabifyNodes () {
      this.$cytoscape.instance.then((cy) => {
        // console.debug("ungrabifying nodes")
        cy.nodes().ungrabify()
      })
    },
    search: debounce(_search, 300),
    filterByKeyword (keyword) {
      this.searchQuery = keyword
      this.subjectArea = null
      _search.bind(this)(keyword)
    },

    filterBySubjectArea (subjectArea) {
      this.$cytoscape.instance.then((cy) => {
        this.showSubjectAreasFilter = false
        removeHighlight(cy)
        this.currentMember = null
        this.searchQuery = subjectArea.name
        cy.nodes().addClass('dimmed')
        const results = cy.nodes().filter((node) => {
          return node.data().SC_SubjectArea_1st__lct === subjectArea.name || node.data().SC_SubjectArea_2nd__lct === subjectArea.name
        }).forEach((node) => {
          cy.getElementById(node.data().id).removeClass('dimmed').addClass('not-dimmed')
        })
        this.resultCount = results.length
        // this.centerCurrentSelection()
      })
    },
    resetFilters () {
      this.$cytoscape.instance.then((cy) => {
        removeHighlight(cy)
        this.currentMember = null
        this.searchQuery = ''
        this.subjectArea = ''
        this.resultCount = cy.nodes().length
      })
    },
    resetFiltersAndCenter () {
      this.$cytoscape.instance.then((cy) => {
        this.resetFilters()
        this.centerNetwork()
      })
    },
    showMember (node) {
      this.$cytoscape.instance.then((cy) => {
        highlightNodeAndNeighbours(node, cy)
        this.currentMember = node
        this.searchQuery = ''
        this.currentSubjectArea = ''
        this.showNodeInfo = true

        this.resultCount = cy.getElementById(node.id).neighborhood().filter(n => n.isNode()).length + 1
      })
    },
    hideMember () {
      this.$cytoscape.instance.then((cy) => {
        this.showNodeInfo = false
        // removeHighlight(cy)
        // this.currentMember = null
      })
    },
    hardReloadWebpage () {
      document.body.style.backgroundColor = 'rgba(0,0,100,0.2)'
      setTimeout(() => { location.reload(true) }, 300)
    },
  },
  mounted () {
    this.$cytoscape.instance.then((cy) => {
      cy.on('tap', 'node', (event) => {
        if (event.target.hasClass('dimmed')) {
          return
        }
        const node = event.target.data()
        this.showMember(node)
      })
      cy.on('taphold', 'node', (event) => {
        // if (event.target.hasClass('dimmed')) {
        //   return
        // }
        const node = event.target.data()
        this.showMember(node)
      })

      // cy.on('tap', (event) => {
      //   if (event.target === cy) {
      //     this.hideMember()
      //     this.searchQuery = ""
      //   }
      // })

      cy.once('render', (event) => {
        console.debug('rendered')
        this.isLoading = false
        cy.resize()
      })
    })
  },
}

</script>


<style lang="scss">
@import "@/css/variables.scss";
$carpet-node-info-height: 200px;
$sa-cell-tissue-architecture-color:        #853AA6;
$sa-cell-cycle-color:                      #39BF8A;
$sa-cellular-metabolism-color:             #CC299B;
$sa-chromatin-transcription-color:         #2A7DD1;
$sa-development-color:                     #E52E40;
$sa-differentiation-death-color:           #E52E77;
$sa-evolution-ecology-color:               #54BF26;
$sa-genome-stability-dynamics-color:       #2EC1E5;
$sa-genomic-computational-biology-color:   #3BC4B7;
$sa-immunology-color:                      #F7891B;
$sa-membranes-transport-color:             #7F5BB5;
$sa-microbiology-virology-pathogens-color: #C5D916;
$sa-molecular-medicine-color:              #E5532E;
$sa-neuroscience-color:                    #FFD11A;
$sa-plant-biology-color:                   #90D42A;
$sa-proteins-biochemistry-color:           #7067BF;
$sa-rna-color:                             #6479CC;
$sa-signal-transduction-color:             #A63AA6;
$sa-structural-biology-biophysics-color:   #39BD60;
$sa-systems-biology-color:                 #38BA38;
.carpet-title {
  display:none;

  color: $dark-title-color;
  text-align: center;
  font-size: 40px;
  padding: 20px;
  font-family: "Georgia", Times, serif;
  text-transform: uppercase;
  word-spacing: 0.6em;
  letter-spacing: 0.3em;
  @media screen and (max-width: 768px) {
    & {
      font-size: 25px;
      padding: 15px 0;
      font-family: "Georgia", Times, serif;
      text-transform: uppercase;
      word-spacing: 0.1em;
      letter-spacing: 0em;
    }
  }
}
.button.is-biggish {
  margin-bottom: 0.5rem;
  padding: 1.35rem 1rem;
  font-size: 17px;
}

.button-toolbar {
  z-index: 20;
  width: 30px;
  position: absolute;
  top: 18px;
  left: 14px;

  .help-text {
    width: 40px;
  }
}

.button.has-just-icon-mobile {
  @media screen and (max-width: 768px) {
    .icon:first-child:not(:last-child) {
      margin-right: calc(-0.375em - 1px);
    }
    .help-text {
      display:none;
    }
  }
}

.search-toolbar {
  z-index: 10;
  position: absolute;
  top: 18px;
  left: 120px;
  @media screen and (max-width: 768px) {
    left: 70px;
    margin-right: 18px;
  }
  input {
    background-color: rgba(0, 0, 0, 0.7);
    border-color: $dark-title-color;
    padding: 0 5px;
    max-width: 220px;
    color: #ddd;
    &:focus {
      border-color: lighten($dark-title-color, 10%);
      box-shadow: 0 0 0 0;
    }
    &:hover {
      border-color: lighten($dark-title-color, 10%);
    }
    font-size: 20px;
  }
}

.subjectarea-toolbar {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.8);
  z-index: 20;
  max-width: 431px;
  position: absolute;
  top: calc(18px + 53px);
  left: calc(65px + 56px);
  .button {
    margin-right: 10px;
    // @media screen and (min-width: 769px) {
    //   max-width: 150px;
    //   min-width: 150px;
    // }
  }
  .icon {
    min-width: 1.5rem; // ensure icons dont get squeezed
  }
  .help-text {
    text-align: left;
    max-width: 150px;
    min-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    left: 0.8em;
  }

  @media screen and (max-width: 768px) {
    left: calc(14px + 56px);
    max-width: 274px; // 4 squares per row
  }

  .sa-cell-tissue-architecture        { background-color: $sa-cell-tissue-architecture-color;        color: $sa-cell-tissue-architecture-color }
  .sa-cell-cycle                      { background-color: $sa-cell-cycle-color;                      color: $sa-cell-cycle-color }
  .sa-cellular-metabolism             { background-color: $sa-cellular-metabolism-color;             color: $sa-cellular-metabolism-color }
  .sa-chromatin-transcription         { background-color: $sa-chromatin-transcription-color;         color: $sa-chromatin-transcription-color }
  .sa-development                     { background-color: $sa-development-color;                     color: $sa-development-color }
  .sa-differentiation-death           { background-color: $sa-differentiation-death-color;           color: $sa-differentiation-death-color }
  .sa-evolution-ecology               { background-color: $sa-evolution-ecology-color;               color: $sa-evolution-ecology-color }
  .sa-genome-stability-dynamics       { background-color: $sa-genome-stability-dynamics-color;       color: $sa-genome-stability-dynamics-color }
  .sa-genomic-computational-biology   { background-color: $sa-genomic-computational-biology-color;   color: $sa-genomic-computational-biology-color }
  .sa-immunology                      { background-color: $sa-immunology-color;                      color: $sa-immunology-color }
  .sa-membranes-transport             { background-color: $sa-membranes-transport-color;             color: $sa-membranes-transport-color }
  .sa-microbiology-virology-pathogens { background-color: $sa-microbiology-virology-pathogens-color; color: $sa-microbiology-virology-pathogens-color }
  .sa-molecular-medicine              { background-color: $sa-molecular-medicine-color;              color: $sa-molecular-medicine-color }
  .sa-neuroscience                    { background-color: $sa-neuroscience-color;                    color: $sa-neuroscience-color }
  .sa-plant-biology                   { background-color: $sa-plant-biology-color;                   color: $sa-plant-biology-color }
  .sa-proteins-biochemistry           { background-color: $sa-proteins-biochemistry-color;           color: $sa-proteins-biochemistry-color }
  .sa-rna                             { background-color: $sa-rna-color;                             color: $sa-rna-color }
  .sa-signal-transduction             { background-color: $sa-signal-transduction-color;             color: $sa-signal-transduction-color }
  .sa-structural-biology-biophysics   { background-color: $sa-structural-biology-biophysics-color;   color: $sa-structural-biology-biophysics-color }
  .sa-systems-biology                 { background-color: $sa-systems-biology-color;                 color: $sa-systems-biology-color }
}

.network {
  width: 100%;
  height: calc(100vh - 10px) !important;
}

.carpet-node-info {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 30;
  width: 100%;
  @media screen and (min-width: 769px) {
    & {
      max-width: 300px;
      bottom: 0px;
      right: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    & {
      // max-height: $carpet-node-info-height;
      bottom: 0px;
    }
  }
}


// .subject-area-filter {
//   select {
//     background-color: $dark-bg-color;
//     // width: 100%;
//     border-color: $dark-title-color;
//     border-radius: 4px;
//     color: #ddd;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     height: 2.25em;
//     font-size: 0.85rem;
//   }
//   select::-ms-expand {
//     display: none;
//   }
//   @media screen and (max-width: 768px) {
//     select, select:focus {
//       padding: 5px;
//       font-size:20px;
//     }
//   }
// }

.control.has-icons-left .icon {
  font-size: 20px;
  height: 2.5em;
  color: $dark-title-color;
}

.button.is-dark-mode {
  background-color: rgba(0, 0, 0, 0.7);
  color: #ddd;
  border-color: $dark-title-color;

  &.is-loading {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 40px;

    background-color: transparent;
    border:0;
    border-color: transparent;
  }
}
.result-count {
  position: fixed;
  top: 10px;
  right: 15px;
  font-size: 40px;
  font-weight: 900;
  color: darken($dark-title-color, 22%);
  z-index:1;
}
</style>
