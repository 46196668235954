const networkStyle = [
  {
    selector: 'node',
    css: {
      'border-color': '#808080',
      'background-color': '#808080',
      'border-width': 1.5,
      width: 10,
      height: 10,
      label: 'data(PS_LastName)',
      'text-valign': 'center',
      'text-halign': 'center',
      'font-size': 4,
      color: 'white',
      'text-outline-width': 0.5,
      'text-outline-color': '#000',
    },
  },
  {
    selector: 'node.dimmed',
    css: {
      opacity: 0.1,
    },
  },
  // {
  //   selector: 'node.not-dimmed',
  //   css: {
  //     width: 20,
  //     height: 20,
  //     label: 'ALEX',
  //   },
  // },
  {
    selector: 'node.selected',
    css: {
      width: 20,
      height: 20,
      'border-width': 2.5,
      'font-size': 6,
    },
  },

  /* *************************************************************************
   *
   * NODE BACKGROUNDS
   *
   ************************************************************************ */
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Cell & Tissue Architecture"]',
    css: {
      'background-color': '#853AA6',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Cell Cycle"]',
    css: {
      'background-color': '#39BF8A',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Cellular Metabolism"]',
    css: {
      'background-color': '#CC299B',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Chromatin & Transcription"]',
    css: {
      'background-color': '#2A7DD1',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Development"]',
    css: {
      'background-color': '#E52E40',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Differentiation & Death"]',
    css: {
      'background-color': '#E52E77',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Evolution & Ecology"]',
    css: {
      'background-color': '#54BF26',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Genome Stability & Dynamics"]',
    css: {
      'background-color': '#2EC1E5',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Genomic & Computational Biology"]',
    css: {
      'background-color': '#3BC4B7',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Immunology"]',
    css: {
      'background-color': '#F7891B',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Membranes & Transport"]',
    css: {
      'background-color': '#7F5BB5',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Microbiology, Virology & Pathogens"]',
    css: {
      'background-color': '#C5D916',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Molecular Medicine"]',
    css: {
      'background-color': '#E5532E',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Neuroscience"]',
    css: {
      'background-color': '#FFD11A',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Plant Biology"]',
    css: {
      'background-color': '#90D42A',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Proteins & Biochemistry"]',
    css: {
      'background-color': '#7067BF',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "RNA"]',
    css: {
      'background-color': '#6479CC',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Signal Transduction"]',
    css: {
      'background-color': '#A63AA6',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Structural Biology & Biophysics"]',
    css: {
      'background-color': '#39BD60',
    },
  },
  {
    selector: 'node[SC_SubjectArea_1st__lct = "Systems Biology"]',
    css: {
      'background-color': '#38BA38',
    },
  },
  /* *************************************************************************
   *
   * NODE BORDERS
   *
   ************************************************************************ */
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Cell & Tissue Architecture"]',
    css: {
      'border-color': '#853AA6',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Cell Cycle"]',
    css: {
      'border-color': '#39BF8A',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Cellular Metabolism"]',
    css: {
      'border-color': '#CC299B',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Chromatin & Transcription"]',
    css: {
      'border-color': '#2A7DD1',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Development"]',
    css: {
      'border-color': '#E52E40',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Differentiation & Death"]',
    css: {
      'border-color': '#E52E77',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Evolution & Ecology"]',
    css: {
      'border-color': '#54BF26',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Genome Stability & Dynamics"]',
    css: {
      'border-color': '#2EC1E5',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Genomic & Computational Biology"]',
    css: {
      'border-color': '#3BC4B7',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Immunology"]',
    css: {
      'border-color': '#F7891B',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Membranes & Transport"]',
    css: {
      'border-color': '#7F5BB5',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Microbiology, Virology & Pathogens"]',
    css: {
      'border-color': '#C5D916',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Molecular Medicine"]',
    css: {
      'border-color': '#E5532E',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Neuroscience"]',
    css: {
      'border-color': '#FFD11A',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Plant Biology"]',
    css: {
      'border-color': '#90D42A',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Proteins & Biochemistry"]',
    css: {
      'border-color': '#7067BF',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "RNA"]',
    css: {
      'border-color': '#6479CC',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Andrzej Dziembowski Mikiko Siomi Janusz Bujnicki"]',
    css: {
      'border-color': '#6479CC',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Signal Transduction"]',
    css: {
      'border-color': '#A63AA6',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Structural Biology & Biophysics"]',
    css: {
      'border-color': '#39BD60',
    },
  },
  {
    selector: 'node[SC_SubjectArea_2nd__lct = "Systems Biology"]',
    css: {
      'border-color': '#38BA38',
    },
  },
  {
    selector: 'edge',
    style: {
      width: 1,
      'curve-style': 'unbundled-bezier',
      'control-point-distances': '20 0 20',
      'line-color': '#999',
    },
  },
]
export default networkStyle
