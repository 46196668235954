<template lang="pug">
  .profile
    .media
      .media-left
        figure
          img.circular-image(:src="imgUrl" @error="handleImageNotFound")
      .media-content
        button.delete.profile-close(@click="$emit('clickClose')")
        h1.is-size-1
          | {{ currentMember.PS_FirstNameLastName__lct }}
        small
          | {{ emboMembership }}
          br
          | {{ institution }}
        br
    .content
      .is-clearfix
        .button.is-pulled-right.is-hidden-tablet.is-small.is-dark-mode(@click="showFullProfile = !showFullProfile" v-if="!showFullProfile")
          span show more
          span.icon.is-small
            fa-icon(icon="chevron-down")
      .extra-info(:class="{'shown':showFullProfile}")
        .tags.are-small.are-dark-mode
          .tag(v-for="keyword in keywords" @click="$emit('clickKeyword', keyword)") {{ keyword }}
        .abstract.is-hidden-mobile
          | {{ longAbstract }}
        .abstract.is-hidden-tablet
          | {{ shortAbstract }}
        br
        .keywords
          | {{ officialKeywords }}
        br
        .is-clearfix
          .button.is-pulled-right.is-hidden-tablet.is-small.is-dark-mode(@click="showFullProfile = !showFullProfile")
            span show less
            span.icon.is-small
              fa-icon(icon="chevron-up")


</template>

<script>


export default {
  props: {
    currentMember: Object,
  },
  data () {
    return {
      showFullProfile: false,
    }
  },
  computed: {
    keywords () {
      const embowords = this.currentMember.SC_EMBOwords__lct.split('\n')

      return embowords
    },
    officialKeywords () {
      return this.currentMember.SC_ML_OfficialKeywords
    },
    imgUrl () {
      if (this.currentMember.PS_LastName === 'Vader') {
        return '/img/vader.jpg'
      } else if (this.currentMember.PS_LastName === 'Sorry') {
        return '/img/sorry_looking.png'
      }
      const imageFileName = `p${String(this.currentMember.name).padStart(6, '0')}`
      return `https://people.embo.org/img/portraits/${imageFileName}.png`
    },
    longAbstract () {
      return this.currentMember.SC_ML_ShortSummary_450chars
    },
    shortAbstract () {
      return this.currentMember.SC_ML_ShortTitle_50chars
    },
    emboMembership () {
      // return `EMBO ${this.currentMember.PS_EMBO_Year_Membership}`
      return this.currentMember.D_Memberships
    },
    institution () {
      return this.currentMember.psub_add_INST__Institution_Current_ContactOnly_IN_FullNameCountry_Print__lct
    },
  },
  methods: {
    handleImageNotFound (event) {
      // console.debug("image not found for ", this.currentMember.PS_FirstNameLastName__lct)
      event.target.src = 'https://people.embo.org/img/placeholder.png'
    },
  },
}

</script>


<style lang="scss" scoped>
@import "@/css/variables.scss";

.circular-image {
  border-radius: 50%;
  object-fit: cover;
  width: 100px;
  height: 100px;
}

.button.is-dark-mode {
  background-color: lighten($dark-bg-color, 5%);
  color: lighten($dark-title-color, 30%);
  // border-color: transparent;
  border-color: lighten($dark-title-color, 30%);
}

.profile {
  padding:20px;
  max-height: 100vh;
  // overflow: scroll;
  @media screen and (max-width: 768px) {
    & {
      padding: 10px;
    }
  }

  color: lighten($dark-title-color, 30%);
  h1, p {
    color: #eee;
  }
}

button.profile-close{
  max-height: 25px;
  max-width: 25px;
  height: 25px;
  width: 25px;
  position: absolute;
  right: 10px;
  background-color: lighten($dark-bg-color, 5%);
}

.tag {
  font-weight: bold;
}

.tags {
  margin: 5px 0 0 0;
  padding-bottom: 0;
  &.are-dark-mode {
    .tag {
      background-color: lighten($dark-bg-color, 5%);
      color: lighten($dark-title-color, 30%);
    }
  }
}

.circular-image {
  width: 50px;
  height: 50px;
}
.is-size-1 {
  font-size: 1rem !important;
}
.is-size-4 {
  font-size: 0.7rem !important;
}
@media screen and (max-width: 768px) {
  .extra-info {
    display: none;
    &.shown {
      display: block;
    }
  }
}

// @media screen and (max-width: 768px) {
//   .abstract {
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     overflow: hidden;

//     &.profile-expanded {
//       white-space: normal;
//     }
//   }
// }
</style>
