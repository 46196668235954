import Vue from 'vue'
import Router from 'vue-router'
import Carpet2019 from './carpet/2019'
import CarpetAbout from './carpet/about'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Carpet2019,
    },
    {
      path: '/about',
      name: 'about',
      component: CarpetAbout,
    },
  ],
})
