import VueCytoscape from 'vue-cytoscape'
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
 import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserSecret,
  faBook,
  faUser,
  faSearch,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faCheck,
  faTimes,
  faQuestion,
  faAngleDoubleLeft,
  faRedo,
  faCompressArrowsAlt,
  faArrowsAlt,
  faHome,
  faEllipsisH,
  faPencilAlt,
  faInfoCircle,
  faInfo,
  faTh,
  faSquare,
  faSquareFull,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './App'
import router from './router'
import store from './store'
import './registerServiceWorker'


library.add(
  faUserSecret,
  faBook,
  faUser,
  faSearch,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faCheck,
  faTimes,
  faQuestion,
  faAngleDoubleLeft,
  faRedo,
  faCompressArrowsAlt,
  faArrowsAlt,
  faHome,
  faEllipsisH,
  faPencilAlt,
  faInfoCircle,
  faInfo,
  faTh,
  faSquare,
  faSquareFull,
)

Vue.component('fa-icon', FontAwesomeIcon)
Vue.use(VueCytoscape)
Vue.use(Vue2TouchEvents)

Vue.config.productionTip = true

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
