import axios from 'axios'

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  options: axios.options,
  defaults: axios.defaults,
}

export const httpMock = {
  get (url, responseData) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: responseData })
      }, 700)
    })
  },
  patch (url, payload) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // resolve(candidatesDummyData)
        resolve({ data: payload })
      }, 1300)
    })
  },
}
