<template lang="pug">
#about
  .button.is-dark-mode.is-fullwidth(@click="goBackHome")
    span.icon
      fa-icon(icon="chevron-left")
    span Back to the network
  br
  .content
    h1.has-text-centered THE EMBO MEMBERSHIP CARPET 2019
    h4
      | Alejandro Riera Mainar & Volker Wiersdorff
      br
      | EMBO Information Support & Resources
    p
      | The network on the carpet includes 1,755 active EMBO Members and EMBO Associate Members who shared at least one EMBO keyword with another EMBO Member in October 2019. Network edges (n=16,259) were weighted with a heuristic parameter calculated from the number, frequency and significance of the shared keywords, and limited to the 10 closest neighbours of each EMBO Member. The 20 EMBO Subject Areas (indicated along the periphery of the carpet) were used to colour-code the nodes.
    p
      | The final rendering of the map in Cytoscape 3.7.2 (Shannon et al. 2003) was optimised to avoid overlap of nodes and ensure readability of the names at production size. An interactive version of the network (carpet.embo.org) was created using the cytoscape.js library (Franz et al. 2016).

    h4 References:
    ol
      li Shannon P, Markiel A, Ozier O, Baliga NS, Wang JT, Ramage D, Amin N, Schwikowski B, Ideker T (2003) Cytoscape: a software environment for integrated models of biomolecular interaction networks. Genome Res. 13: 2498–2504
      li Franz M, Lopes CT, Huck G, Dong Y, Sumer O, Bader GD (2016) Cytoscape.js: a graph theory library for visualisation and analysis. Bioinformatics 32: 309–311; doi:10.1093/bioinformatics/btv557


</template>

<script>
export default {
  methods: {
    goBackHome () {
      location.href = '/'
    },
  },
}
</script>


<style lang="scss" scoped>
@import "@/css/variables.scss";

#about {
  margin: 0 auto;
  max-width: 500px;
  padding: 30px;
  color: #ccc;
  h1, h4, li {
    color: #ccc;
  }
}
</style>
