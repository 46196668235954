<template lang="pug">
  #app
    section.section
      router-view
    //- footer
    //-   .content.has-text-centered
    //-     p Copyright © {{ (new Date()).getFullYear() }} EMBO. All Rights Reserved.
</template>

<script>
export default {
  data () {
    return {
      navExpanded: false,
    }
  },
  methods: {
    toggleNav () {
      this.navExpanded = !this.navExpanded
    },
  },
  beforeCreate () {
  },
}
</script>


<style lang="scss">
  @import './css/main.scss';
  @import "@/css/variables.scss";
</style>
